<template>
  <div id="wrapper">
    <Navigation @globalShortcutCalled="focusNewNote" />
    <h1><i class="fal fa-sticky-note"></i>Your notes</h1>
    <form @submit="addNote">
      <textarea ref="new_note" @keyup="textAreaAdjust" style="width:100%;height:40px;max-height:none;" placeholder="New note" v-model="new_note"></textarea>
      <div style="width:100%;text-align:center;">
        <button type="submit" class="add_button"><i class="fal fa-plus"></i></button>
      </div>
    </form>
    <div class="notes">
      <Note v-for="note in notes" :key="note.id" :note="note" />
    </div>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
import Note from "../components/overview/Note";
/*import { remote } from 'electron';*/

export default {
  name: "overview",
  data() {
    return {
      notes: [],

      new_note: ""
    };
  },
  mounted() {
    this.loadNotes();
    /*remote.getCurrentWindow().setTouchBar(null);*/
  },
  methods: {
    async loadNotes() {
      var response = await storage.get(
        "notes",
        [
          {
            column: "space",
            operator: "==",
            value: "0",
          },
        ],
        [
          {
            column: "modify_datetime",
            order: "desc",
          },
        ]
      );

      if (response.isOK) {
        this.notes = response.data;
      } else {
        this.$notibar.add(response.message);
      }
    },
    focusNewNote() {
      setTimeout(function() {
        this.$refs.new_note.focus();
      }.bind(this), 200);
    },
    async addNote(e) {
      e.preventDefault();

      var response = await storage.insert("notes", {
        note: this.new_note,
        create_datetime: new Date().getTime(),
        modify_datetime: new Date().getTime(),
        space: 0,
      });

      if (response.isOK) {
        this.loadNotes();
        this.new_note = "";
        this.$notibar.add("The note has been saved.");
      } else {
        this.$notibar.add(response.message);
      }
    },
    async textAreaAdjust(event) {
      if (event.ctrlKey && event.keyCode == 83) {
        event.preventDefault();
        await this.addNote(event);
        this.textAreaAdjust({target: event.target});
      }

      var element = event.target;
      element.style.height = "1px";
      element.style.height = (25+element.scrollHeight)+"px";
    }
  },
  components: {
    Note
  }
};
</script>

<style scoped>
#wrapper {
  overflow-y: auto;
}

@media screen and (min-width: 801px) {
  .add_button {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .add_button {
    width: 100%;
  }
}
</style>