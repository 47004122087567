import { sCAuth } from "schmucklicloud_auth";
import { sCStorage } from "schmucklicloud_storage";

const APP_ID = "70f60d20f53a9555fbe8cdcd3780e5babf8e8f56";
const APP_SECRET = "2ab26664b5dedfabb2ee0239d0cb26efb00fd38d0854dc5ded0302a7a08a0d2c";

var auth = new sCAuth(APP_ID, APP_SECRET);
var storage = new sCStorage(APP_ID, APP_SECRET);

storage.setBucket(24);

var session_token = window.localStorage.getItem("session_token");

storage.setAuthToken(session_token);

export { auth, storage };