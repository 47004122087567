<template>
  <div class="note" @click="openDetail">
    <p class="selectable" v-html="formatter.nl2br(preview_note)"></p>
  </div>
</template>

<script>
import Formatter from "../../handlers/formatter";

export default {
  props: {
    note: Object
  },
  data() {
    return {
      formatter: Formatter,
      preview_note: ""
    };
  },
  mounted() {
    this.preview_note = this.note.data.note.substring(0, 300);
    if (this.preview_note.length !== this.note.data.note.length) {
      this.preview_note += "...";
    }
  },
  methods: {
    openDetail() {
      this.$router.push("/note/" + this.note.id);
    }
  }
};
</script>

<style>
.note {
  margin-bottom: 10px;
  cursor: pointer;
  min-height: 100px;
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;

  padding: 5px;
  border-radius: 5px;
  font-size: 17px;
  border: none;
  box-shadow: 0px 0px 5px rgb(216, 216, 216);
  outline: none;
  background: rgba(255, 255, 255, 0.329);
  -webkit-app-region: no-drag;
}

@media (prefers-color-scheme: dark) {
  .note {
    background: rgba(0, 0, 0, 0.329);
    box-shadow: 0px 0px 5px rgb(53, 53, 53);
  }
}
</style>