import { languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-abap';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-regex';
import 'prismjs/components/prism-matlab';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-http';
import 'prismjs/components/prism-git';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-powershell';

var codes = [
    {
        name: "abap",
        component: languages.abap,
        text: "ABAP"
    },
    {
        name: "bash",
        component: languages.bash,
        text: "Bash"
    },
    {
        name: "css",
        component: languages.css,
        text: "CSS"
    },
    {
        name: "docker",
        component: languages.docker,
        text: "Docker"
    },
    {
        name: "git",
        component: languages.git,
        text: "Git"
    },
    {
        name: "http",
        component: languages.http,
        text: "HTTP"
    },
    {
        name: "java",
        component: languages.java,
        text: "Java"
    },
    {
        name: "js",
        component: languages.js,
        text: "JavaScript"
    },
    {
        name: "kt",
        component: languages.kotlin,
        text: "Kotlin"
    },
    {
        name: "md",
        component: languages.markdown,
        text: "Markdown"
    },
    {
        name: "matlab",
        component: languages.matlab,
        text: "MatLab"
    },
    {
        name: "powershell",
        component: languages.powershell,
        text: "PowerShell"
    },
    {
        name: "python",
        component: languages.python,
        text: "Python"
    },
    {
        name: "regex",
        component: languages.regex,
        text: "Regex"
    },
    {
        name: "sql",
        component: languages.sql,
        text: "SQL"
    },
    {
        name: "swift",
        component: languages.swift,
        text: "Swift"
    },
    {
        name: "xml",
        component: languages.markup,
        text: "XML/HTML"
    }
];

export default codes;