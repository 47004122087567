<template>
  <div id="wrapper">
    <h1>Welcome to Notetastic</h1>
    <p>Take simple and very quick notes with this app.</p>
    <form @submit="login" v-if="loaded">
      <p style="margin-bottom:5px;">But first, sign in with your account.</p>
      <input type="text" v-model="email" placeholder="E-Mail" />
      <input type="password" v-model="password" placeholder="Password" />
      <br />
      <button type="submit" style="margin-top:10px;margin-bottom:30px;">login</button>
      <br />
      <a
        href="#"
        @click="open('https://notetastic.schmuckli.dev/#/register')"
      >Do you want to register?</a>
    </form>
    <form v-else>
      <Loading />
    </form>
  </div>
</template>

<script>
import { auth, storage } from "../schmucklicloud";

export default {
  name: "landing-page",
  data() {
    return {
      email: "",
      password: "",

      loaded: false,
    };
  },
  mounted() {
    this.checkActiveSession();
  },
  methods: {
    async checkActiveSession() {
      var session = window.localStorage.getItem("session_token");
      if (session !== undefined) {
        var response = await auth.checkSession(session);
        if (response.isOK) {
          storage.setAuthToken(session);
          this.$router.replace("/overview");
        } else {
          this.loaded = true;
        }
      } else {
        this.loaded = true;
      }
    },
    open(link) {
      this.$electron.shell.openExternal(link);
    },
    async login(e) {
      e.preventDefault();

      var response = await auth.authorizeEmailPassword(
        this.email,
        this.password
      );
      if (response.isOK) {
        window.localStorage.setItem(
          "session_token",
          response.data.session_token
        );
        storage.setAuthToken(response.data.session_token);
        this.$notibar.add("Successfully signed in.");
        this.$router.replace("/overview");
      } else {
        this.$notibar.add(response.message);
      }
    },
  },
};
</script>

<style>
@import url("~@/assets/css/main.css");
</style>
