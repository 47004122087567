import Vue from 'vue'
import axios from 'axios'

import App from './App'
import router from './router'
import VueNotibar from "vue-notibar";
import moment from "moment";

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import Navigation from "./components/general/Navigation";
import Loading from "./components/general/Loading";

Vue.use(VueNotibar);
Vue.component('PrismEditor', PrismEditor);
moment.locale(navigator.language);
Vue.prototype.moment = moment;

Vue.component("Navigation", Navigation);
Vue.component("Loading", Loading);

Vue.http = Vue.prototype.$http = axios
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  components: { App },
  router,
  template: '<App/>'
}).$mount('#app')
