<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
//const { nativeTheme } = require("electron").remote;

export default {
  name: "note",
  mounted() {
    this.setTheme();
  },
  methods: {
    setTheme() {
      /*var selectedTheme = localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : "system";
      nativeTheme.themeSource = selectedTheme;*/
    },
  },
};
</script>

<style>
/* CSS */
</style>
