import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'landing-page',
      component: require('@/views/LandingPage').default
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: "/overview",
      name: "overview",
      component: require('@/views/Overview').default
    },
    {
      path: "/note/:id",
      name: "note_detail",
      component: require('@/views/NoteDetail').default
    },
    {
      path: "/settings",
      name: "settings",
      component: require('@/views/Settings').default
    }
  ]
})
