<template>
  <div id="wrapper">
    <Navigation />
    <div style="display:flex;width:100%;">
      <h1 style="flex-grow:1;">
        <i @click="back" class="fal fa-arrow-left"></i>Details
      </h1>
      <div style="text-align:right;padding-top:10px;">
        <span @click="editNote" style="cursor:pointer;color:grey;margin-left:10px;">
          <i :class="{fal: true, 'fa-cloud': store_message === 'Saved', 'fa-cloud-upload': store_message !== 'Saved'}"></i> {{ store_message }}
        </span>
        <span @click="deleteNote" style="cursor:pointer;color:red;margin-left:10px;">
          <i class="fal fa-trash"></i> <span class="hideOnMobile">Delete</span>
        </span>
      </div>
    </div>

    <!--<p style="margin-top:20px;margin-bottom:50px;" v-if="!edit_mode" class="selectable" v-html="formatter.nl2br(note.note)"></p>-->
    <textarea ref="notearea" @keyup="saveAutomatically" v-if="code_language === ''" class="notearea" v-model="edit_note"></textarea>
    <PrismEditor ref="notearea" v-else v-model="edit_note" :highlight="highlighter" :code="edit_note" :emitEvents="true" class="notearea" @keyup="saveAutomatically"></PrismEditor>
    <div style="display:flex;">
      <div class="note_actions" style="width:50%;text-align:left;display:inline-block;">
        <select v-model="code_language" @change="updateCodeLanguage">
          <option value="">-</option>
          <option v-for="code in code_languages" :key="code.name" :value="code.name">{{ code.text }}</option>
        </select>
      </div>
      <div class="note_meta" style="width:50%;text-align:right;display:inline-block;">
        <span>Modified: {{ moment.unix(Math.round(note.modify_datetime / 1000)).calendar() }}</span>
        <br />
        <span>Created: {{ moment.unix(Math.round(note.create_datetime / 1000)).calendar() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
/*const { remote } = require("electron");
const { TouchBar } = remote;
const { TouchBarLabel, TouchBarButton } = TouchBar;*/

import { storage } from "../schmucklicloud";
import Formatter from "../handlers/formatter";
import code_languages from "../handlers/code_languages";

import { highlight } from 'prismjs/components/prism-core';
import 'prismjs/themes/prism-tomorrow.css';

export default {
  data() {
    return {
      id: 0,
      note: {},
      formatter: Formatter,
      code_languages: code_languages,

      store_message: "Saved",

      code_language: "",
      edit_note: "",
      timeout: null
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadNote();
  },
  methods: {
    async saveAutomatically() {
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }

      if (event.ctrlKey && event.keyCode == 83) {
        event.preventDefault();
        await this.editNote();
      } else {
        if (this.store_message !== "Saving...") {
          this.store_message = "Not saved";
        }

        this.timeout = setTimeout(
          function() {
            this.store_message = "Saving..."
            this.editNote();
          }.bind(this),
          2000
        );
      }
    },
    async editNote() {
      var time = new Date().getTime();
      var response = await storage.update("notes", this.id, {
        note: this.edit_note,
        modify_datetime: time
      });
      if (response.isOK) {
        this.note.modify_datetime = time;
        setTimeout(function() {
          this.$refs.notearea.focus();
        }.bind(this), 200);
        this.store_message = "Saved";
        this.note.note = this.edit_note;
        this.setTouchbar();
        return true;
      } else {
        this.$notibar.add(response.message);
        return false;
      }
    },
    async updateCodeLanguage() {
      var code_language = this.code_language;
      this.code_language = "";
      var response = await storage.update("notes", this.id, {
        code_language: code_language
      });
      if (response.isOK) {
        this.$notibar.add("Code language has been updated.");
      } else {
        this.$notibar.add(response.message);
      }
      this.$nextTick().then(() => {
        this.code_language = code_language;
      });
    },
    async deleteNote() {
      if (confirm("Do you really want to delete this note?")) {
        var response = await storage.delete("notes", this.id);
        if (response.isOK) {
          this.$notibar.add("The note has been deleted.");
          this.$router.replace("/overview");
        } else {
          this.$notibar.add(response.message);
        }
      }
    },
    async loadNote() {
      var response = await storage.getById("notes", this.id);
      if (response.isOK) {
        this.note = response.data.data;
        this.edit_note = this.note.note;
        this.code_language = this.note.code_language || "";

        this.setTouchbar();
      }
    },
    setTouchbar() {
      /*remote.getCurrentWindow().setTouchBar(
        new TouchBar({
          items: [
            new TouchBarButton({
              label: "Delete",
              click: this.deleteNote
            }),
            new TouchBarLabel({
              label: this.note.note.replace(/(?:\r\n|\r|\n)/g, ' ').substr(0, 90)
            })
          ]
        })
      );*/
    },
    highlighter(code) {
      var component;
      code_languages.forEach(function(code_language) {
        if (code_language.name === this.code_language) {
          component = code_language.component;
        } 
      }.bind(this));
      return highlight(code, component); //returns html
    },
    async back() {
      if (this.edit_note === this.note.note) {
        this.$router.replace("/overview");
      } else {
        if (await this.editNote()) {
          this.$router.replace("/overview");
        } else {
          if (confirm("An error occured while saving the note. Do you want to go back anyway?")) {
            this.$router.replace("/overview");
          }
        }
      }
    }
  }
};
</script>

<style scoped>
h1 i {
  cursor: pointer;
}

.note_meta {
  color: grey;
  font-size: 10px;
}

.notearea {
  width: 100%;
  height: 81%;
  margin-top: 20px;
  padding: 0;
  font-size: 16px;
  background: transparent;
  border: none;
  box-shadow: none;
}
</style>