<template>
  <div id="navigation">
    <ul>
      <li id="logo">
        <img src="../../assets/logo.png" width="30" height="30" />
      </li>
      <li @click="openOverview">
        <i class="fal fa-sticky-note"></i>
        Notes
      </li>
      <li @click="openSettings">
        <i class="fal fa-cog"></i>
        Settings
      </li>
      <!--<li :style="{color: !isSticked ? 'grey' : 'green'}" @click="toggleStickWindow">
        <i class="fal fa-map-pin"></i>
        <span>{{ isSticked ? 'Fixed' : 'Unfixed' }}</span>
      </li>-->
    </ul>
  </div>
</template>

<script>
//const { globalShortcut } = require("electron").remote;

export default {
  data() {
    return {
        isSticked: false
    };
  },
  mounted() {
    this.getCurrentStickStatus();
    this.setShortcuts();
  },
  methods: {
    setShortcuts() {
      /*globalShortcut.register('CommandOrControl+Shift+N', function() {
        this.toggleStickWindow();
        this.$emit("globalShortcutCalled");
      }.bind(this));*/
    },
    openOverview() {
      this.$router.replace("/overview");
    },
    openSettings() {
      this.$router.replace("/settings");
    },
    getCurrentStickStatus() {
      /*var current_window = require("electron").remote.getCurrentWindow();
      this.isSticked = current_window.isAlwaysOnTop();*/
    },
    toggleStickWindow() {
      /*var current_window = require("electron").remote.getCurrentWindow();
      current_window.setAlwaysOnTop(!current_window.isAlwaysOnTop());
      this.isSticked = current_window.isAlwaysOnTop();
      if (this.isSticked && current_window.isMinimized()) {
        current_window.restore();
      }*/
    },
  },
};
</script>

<style scoped>
img {
  display: inline-block;
}
ul {
  list-style-type: none;
}
ul li {
  cursor: pointer;
}
@media screen and (min-width: 801px) {
  #navigation {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 10%;
  }
  ul {
    text-align: center;
    margin-top: 30px;
  }
  ul li {
    padding: 5px;
    margin-top: 20px;
    font-size: 12px;
  }
  ul i.fal {
    font-size: 17px;
    display: block;
  }
}
@media screen and (max-width: 800px) {
  #navigation {
    position: relative;
    max-width: 100%;
    margin-bottom: 20px;
  }
  ul {
    text-align: left;
  }
  ul li {
    display: inline;
  }
  ul li:not(:nth-child(2)) {
    margin-left: 20px;
  }
  #logo {
    display: none;
  }
}
</style>