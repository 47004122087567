<template>
  <div id="wrapper">
      <Navigation />
      <h1><i class="fal fa-cog"></i>Settings</h1>
      <div style="margin-top:20px;">
        <p><b>My account</b>: {{ email }} (<a href="#" @click="logout">Logout</a>)</p>
      </div>
      <h3 style="margin-bottom:0px;">Theme</h3>
      <div style="margin-top:0px;width:100%;">
        <div class="theme theme-system">
          <input type="radio" v-model="theme" value="system">
          <p>System default</p>
        </div>
        <div class="theme theme-light">
          <input type="radio" v-model="theme" value="light">
          <p>Light</p>
        </div>
        <div class="theme theme-dark">
          <input type="radio" v-model="theme" value="dark">
          <p>Dark</p>
        </div>
      </div>
  </div>
</template>

<script>
import { auth } from '../schmucklicloud';
//const { nativeTheme } = require("electron").remote;

export default {
  data() {
    return {
      email: "",
      theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : 'system'
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async logout() {
      var session_token = localStorage.getItem("session_token");
      var response = await auth.logout(session_token);
      if (response.isOK) {
        this.$notibar.add("Logout successful");
        this.$router.replace("/");
      } else {
        this.$notibar.add(response.message);
      }
    },
    async getProfile() {
      var session_token = localStorage.getItem("session_token");
      var response = await auth.getUserDetails(session_token);
      if (response.isOK) {
        this.email = response.data.email;
      }
    }
  },
  watch: {
    theme(val) {
      localStorage.setItem("theme", val);
      //nativeTheme.themeSource = val;
    }
  }
}
</script>

<style>
#wrapper {
  overflow: auto;
}

.theme {
  padding: 30px 20px 10px 20px;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  width: 200px;
  margin: 10px;
}

.theme input {
  box-shadow: none;
}

.theme-system {
  background: rgb(231, 231, 231);
  background: linear-gradient(94deg, rgb(231, 231, 231) 0%, rgba(0,0,0,1) 100%);
}

.theme-light {
  background: rgb(231, 231, 231);
  color: black;
}
.theme-dark {
  background: rgb(12, 12, 12);
  color: white;
}
</style>